import React, { Component } from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import PropTypes from 'prop-types';
import Gallery from "react-photo-gallery";
import { Link } from "gatsby";
import Carousel, { Modal, ModalGateway } from "react-images";


class GalleryPageTemplate extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentImage: 0,
      viewerIsOpen: false,

    }

    this.openLightbox = (event, { photo, index }) => {
      this.setState({currentImage:index,viewerIsOpen:true});
    };

    this.closeLightbox = () => {
      this.setState({currentImage:0,viewerIsOpen:false});
    };

  }


	componentDidMount() {

    //state = { viewerIsOpen: false, currentImage:0 };
    //this.setState(state => ({ viewerIsOpen: false, currentImage: 0  }));
	}

	render() {

        var content = this.props.data.contentfulGalerie;

        //gets photo ready
        var photos = [];
        content.images.forEach(image => {

          if (image.fluid &&  image.file.details.image) {
            photos.push(
              {
                srcSet: image.fluid.srcSet,
                src: image.fluid.src,
                size: image.fluid.sizes,
                width: image.file.details.image.width,
                height: image.file.details.image.height,
              }
            )
         }
        })

        

		return(
		<Layout>
    <SEO title={content.topic.title + ' - ' + content.title} description={content.metaDescription} keywords={content.metaKeywords} />
    <section className="module" id="profile">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="m-title c-align">
            <h1>{content.title}</h1>
            <h6><Link to={"/" + content.topic.url}>&lt; Toutes les galeries {content.topic.title}</Link></h6>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          

          <Gallery photos={photos} onClick={this.openLightbox}/>
          <ModalGateway>
        {this.state.viewerIsOpen ? (
          <Modal onClose={this.closeLightbox}
          styles={{
            positioner: base => ({
              ...base,
              zIndex: '1110',
            }),
            blanket: base => ({
              ...base,
              zIndex: '1100',
            })
          }}>
            <Carousel
              currentIndex={this.state.currentImage}
              views={photos}
            />
          </Modal>
        ) : null}
      </ModalGateway>

        </div>
      </div>
    </div>
  </section>

	  </Layout>)
	}
}




GalleryPageTemplate.propTypes = {
	data: PropTypes.object.isRequired,
	
  }

export default GalleryPageTemplate


export const pageQuery = graphql`
  query ($id: String!) {
      contentfulGalerie(id: {eq: $id}) {
          title
          topic {
              title
              url
          }
          metaKeywords
          metaDescription
          images {
              fluid (maxWidth: 1920) {
                sizes
                src
                srcSet
              }
              file {
                details {
                  image {
                    height
                    width
                  }
                }
              }
          }
      }
  }
`